import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

const [d,startDateStr] = [document,'2001/04/01'];

export default class DATE_COUNTER {
  constructor() {
    dayjs.locale('ja');
    dayjs.extend(relativeTime);
    this.today = dayjs();
    this.run();
  }

  run () {
    this.container = d.querySelectorAll('[data-plugin="dateCounter"]');
    this.container.forEach( v => {
      const startDateObj = dayjs( v.dataset.startDate? v.dataset.startDate : startDateStr );
      this.applydata( v, ...this.diff(startDateObj) )
    });
  }

  diff (startDateObj) {
    const diffMonth = this.today.diff( startDateObj, 'month');
    return [ Math.floor(diffMonth / 12), diffMonth % 12 ];
  }

  applydata ( container, year, month ){
    const yearElms = container.querySelectorAll('.year');
    const monthElms = container.querySelectorAll('.month');
    yearElms.forEach( v => v.innerHTML = `${year}` );
    monthElms.forEach( v => v.innerHTML = `${month}` );
  }
  
}